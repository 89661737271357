@import "../../stylesheets/vars";
@import "../../stylesheets/unit_header";

.unit-header-container {
    background-color: $background;
    padding: 15px 0 25px 0;

    @media (max-width: $tablet-max) {
        padding: 15px 15px 25px 15px;
    }

    width: 100%;

    .sds--metadata-list {
        margin-top: auto;
    }

    p.short-name span {
        font-weight: 600;
    }


    .icon-list-items {
        margin-top: 15px;
        .icon-list-item {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: 10px;
        }

        span {
            &.not-connected, &.not-available {
                color: var(--sds--color--red--400)
            }

            &.all-connected {
                font-weight: 600;
            }

        }

        svg {
            width: 24px;
            height: auto;
        }
    }

    .unit-header {
        max-width: $medium;
        margin: 0 auto;
        width: 100%;
        display: flex;

        @media (max-width: $compact-medium) {
            flex-direction: column;
        }


        .image {
            display: flex;
            align-items: center;

            svg {
                width: 50px;
                height: 30px;
                color: $primary-orange;
                fill: $primary-orange;

                &.child {
                    color: black;
                    fill: black;
                }
            }

            img {
                max-height: 300px;
                max-width: 300px;
                border-radius: 5px;
            }

            @media (max-width: $compact-medium) {
                display: none;
            }

        }

        .obj-name {
            margin-left: 26px;
            display: flex;
            flex-direction: column;

            .meta-info-container {
                display: flex;

            }

            span.name {
                color: black;
                font-weight: bold;
            }

            span.description {
                margin: 20px 0;
            }
        }

        .labels {
            margin: 10px auto 0 0;
            padding-right: 55px;

            span.chip-container {
                display: inline-block;
                margin: 0 10px 10px 0;
            }
        }

    }

    .action-menu-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-left: auto;

        button {
            width: 100%;
            min-width: 150px;

            &.sds--btn--secondary {
                min-width: 180px;
            }
        }
    }

    .other-options, .sds--user-info--dropdown {
        background-color: $background;
    }

    .sds--menu-button .sds--user-info--dropdown {
        z-index: 99;
    }

}
