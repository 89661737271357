@import "../../stylesheets/vars.scss";

.cropped-image-field {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    grid-column-start: second;
    grid-row-start: 1;
    grid-row-end: 99;

    &.second-row {
        grid-row-start: 2;
    }

    label.info {
        display: inline-block;
        margin: 12px 0 6px 0;
        min-width: 210px;
        color: black;
        position: relative;
        font-weight: 600;
    }

    .file-upload {
        display: flex;
        margin: 5px 0;
        flex-direction: column;
    }

    .preview {
        img.cropped-img {
            width: 240px;
            height: auto;
            border-radius: 4px;
        }
    }

    .no-image svg {
        width: 240px;
        height: auto;
        padding: 50px;
        border: .0625rem dashed var(--sds--color--gray--300);
        fill: $lighter-grey;
    }

    .no-image.error svg {
        border-color: $error-red;
        fill: $error-red-background;
    }

    .file-upload-actions {
        display: flex;
        margin-top: 15px;

        a {
            max-width: 240px;
        }

    }

}
